import { Config, Picture } from "./config.interface"
import imageManager from "./imageManager";
import Drawer from "./drawer"
import Hammer, { Rotate } from "hammerjs"
import { Observable, Subject } from "rxjs"
export interface Poi { id: string, color: number[] }
export interface Pois { [key: string]: Poi }

interface Frame {
    pic: {
        sd: {
            path: string,
            image: HTMLImageElement | undefined
        },
        hd: {
            path: string,
            image: HTMLImageElement | undefined
        }
    }
    wire: {
        sd: {
            path: string,
            image: HTMLImageElement | undefined
        },
        hd: {
            path: string,
            image: HTMLImageElement | undefined
        }
    }
}

enum Status {
    NEW,
    FIRST_FRAME_LOADED,
    BLUR_LOADED,
    FULL_LOADED
}

interface Scene {
    wiresId: string[]
    name: string;
    wire: boolean;
    frames: Frame[];
    status: Status;
    groupId: number
}

export const sleep = async (ms?: number) => {
    return new Promise(r => setTimeout(r, ms ? ms : 0))
}

export const pad = (num: number, size: number, reverseSize?: number) => {
    num = reverseSize ? reverseSize - num : num
    const s = "000000000" + num;
    return s.substr(s.length - size);
}

declare var DocumentTouch: any;
export const is_touch_device4 = () => {

    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');

    const mq = function (query: any) {
        return window.matchMedia(query).matches;
    }

    if (('ontouchstart' in window) || (window as any).DocumentTouch && document instanceof DocumentTouch) {
        return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
}

export default class {
    container!: HTMLElement;
    imageManager = new imageManager()
    config!: Config;
    drawer: Drawer;
    activeScene!: Scene;
    scenes: Scene[] = [];
    angle = 0;
    divContainer = document.createElement("div")
    glCanvas = document.createElement("canvas")
    mouseCanvas = document.createElement("canvas")
    mouseCtx: CanvasRenderingContext2D;
    pxRatio = window.devicePixelRatio | 1;
    displayWidth = 0;
    displayHeight = 0;
    lastContainerWidth = -1;
    lastContainerHeight = -1;
    overflow = 5
    rotationSensitivity = 1;
    wireBaseBlur = 1;
    wireData?: Uint8ClampedArray;
    hammer!: HammerManager;
    activeFrame!: Frame;
    lastActiveFrame!: Frame;
    pois: Pois = {};
    isTUrning = false;
    isTouch = is_touch_device4()
    loadImageIndex = 0;
    isColorCHanged = false;
    events = {
        mouseMove: new Subject<{ poi: Poi | undefined, clientX: number, clientY: number }>(),
        tap: new Subject<{ poi: Poi | undefined, clientX: number, clientY: number }>(),
        loading: new Subject<boolean>(),
        resize: new Subject<{ totalWidth: number, width: number, height: number }>(),
        startRotating: new Subject(),
        rotate: new Subject<number>()
    }
    constructor() {
        this.drawer = new Drawer(this.glCanvas)
        this.mouseCtx = this.mouseCanvas.getContext("2d") as CanvasRenderingContext2D;
        this.listenResize()
        this.startTick()
    }

    public applyColorPoi() {
        this.isColorCHanged = true;
    }

    private async startTick() {
        while (true) {
            try {
                if (this.lastActiveFrame !== this.activeFrame) {
                    if (this.activeScene.status >= Status.BLUR_LOADED) {
                        if (!this.drawer.lastPicDrawed || this.drawer.lastPicDrawed.image !== this.activeFrame.pic.hd.image) {
                            // PAINT
                            if (this.activeScene.status !== Status.FULL_LOADED) {
                                this.glCanvas.style.filter = `blur(${Math.floor(6)}px)`;
                            } else {
                                this.glCanvas.style.filter = `blur(${Math.floor(0)}px)`;
                            }
                            this.drawer.paint(
                                this.activeFrame.pic[this.activeScene.status === Status.FULL_LOADED ? "hd" : "sd"].image as HTMLImageElement,
                                this.activeFrame.wire[this.activeScene.status === Status.FULL_LOADED ? "hd" : "sd"].image as HTMLImageElement
                            )
                        }
                    }
                    this.lastActiveFrame = this.activeFrame;
                }
                if (this.activeScene) {
                    if (this.isColorCHanged) {
                        this.drawer.setColors(this.activeScene.wiresId.map(e => this.pois[e].color))
                        this.isColorCHanged = false;
                    }
                }

            } catch (error) {
                console.error(error);
            }
            await new Promise(r => requestAnimationFrame(r))
        }
    }


    public setConfig(config: Config) {
        this.config = config;
        const pois: Pois = {}
        for (let pic of config.pictures) {
            if (pic.wireColors) {
                for (let wire of pic.wireColors) {
                    pois[wire.name] = { id: wire.name, color: Array.from({ length: 4 }).map(e => Math.random()) }
                }
            }
            this.scenes.push({
                name: pic.name,
                wire: pic.wire,
                groupId: pic.groupId,
                wiresId: pic.wireColors ? pic.wireColors.map(e => e.name) : [],
                status: Status.NEW,
                frames: Array.from({ length: pic.length }).map((e, i) => {
                    return {
                        pic: {
                            hd: {
                                path: `/pictures/${pic.name}/hd/pic/${pad(i, 4)}.jpg`,
                                image: undefined
                            },
                            sd: {
                                path: `/pictures/${pic.name}/sd/pic/${pad(i, 4)}.jpg`,
                                image: undefined
                            }
                        },
                        wire: {
                            hd: {
                                path: `/pictures/${pic.name}/hd/wire/${pad(i, 4)}.png`,
                                image: undefined
                            },
                            sd: {
                                path: `/pictures/${pic.name}/sd/wire/${pad(i, 4)}.png`,
                                image: undefined
                            }
                        }
                    }
                }).reverse()
            })
        }
        this.pois = pois;
        return pois;
    }

    private async listenResize() {
        while (true) {
            if (this.container && this.config && (this.container.clientWidth !== this.lastContainerWidth || this.container.clientHeight !== this.lastContainerHeight)) {
                this.lastContainerWidth = this.container.clientWidth;
                this.lastContainerHeight = this.container.clientHeight;
                this.handleSize()
            }
            await sleep(500);
        }
    }

    public handleSize() {
        if (this.displayWidth !== this.container.clientWidth) {
            // this.pxRatio = window.devicePixelRatio | 1;
            this.pxRatio = 1;
            this.displayWidth = this.container.clientWidth
            this.displayHeight = this.displayWidth * (this.config.height / this.config.width)
            if (this.displayHeight > this.container.clientHeight) {
                this.displayHeight = this.container.clientHeight
                this.displayWidth = this.container.clientHeight * (this.config.width / this.config.height)
            }
            this.divContainer.style.width = `${this.displayWidth}px`;
            this.divContainer.style.height = `${this.displayHeight}px`;

            this.glCanvas.width = this.displayWidth * this.pxRatio;
            this.glCanvas.height = this.displayHeight * this.pxRatio;

            this.glCanvas.style.width = `${this.displayWidth + this.overflow * 2}px`;
            this.glCanvas.style.height = `${this.displayHeight + this.overflow * 2}px`;
            this.glCanvas.style.top = `${-this.overflow}px`;
            this.glCanvas.style.left = `${-this.overflow}px`;
            if (this.activeScene) {
                this.setHdFrame()
            }
            this.drawer.updateGlSize()
            this.events.resize.next({ totalWidth: this.container.clientWidth, width: this.displayWidth, height: this.displayHeight })
        }
    }

    private getActualFrame() {
        return this.activeScene.frames[Math.floor(this.angle / 360 * this.activeScene.frames.length)];
    }

    private setAngle(angle: number) {
        angle = angle % 360;
        if (angle > 360) {
            angle += -360;
        } else if (angle < 0) {
            angle += 360
        }
        this.events.rotate.next(angle)
        this.angle = angle;
        this.activeFrame = this.getActualFrame()
    }

    private getPoiFromPos(xRatio: number, yRatio: number) {
        if (xRatio < 1 && yRatio < 1) {
            const data = this.mouseCtx.getImageData(this.mouseCanvas.width * xRatio, this.mouseCanvas.height * yRatio, 1, 1).data
            if (data[0] !== 255) {
                const res = this.activeScene.wiresId[data[0]]
                return res ? res : false;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    private handleTouch() {
        this.hammer = new Hammer(this.glCanvas)
        let startAngle = 0;
        let lastPoiOvered: string | undefined;
        let panStarted = false;
        this.hammer.on('panstart', (e) => {
        })

        this.hammer.on('tap', (e) => {
            const poi = this.getPoiFromPos((e.srcEvent as any).offsetX / this.displayWidth, (e.srcEvent as any).offsetY / this.displayHeight)
            this.events.tap.next({ poi: poi ? this.pois[poi] : undefined, clientX: e.center.x, clientY: e.center.y })
        })

        this.hammer.on('panmove', (e) => {
            if (e.direction === 2 || e.direction == 4) {
                if (!panStarted) {
                    if (this.activeScene.status >= Status.BLUR_LOADED) {
                        this.glCanvas.style.transition = `0s filter linear`
                        // this.glCanvas.style.filter = `blur(${Math.floor(this.activeScene.status !== Status.FULL_LOADED ? 5 : 0)}px)`;
                        startAngle = this.angle
                        this.isTUrning = true;
                        panStarted = true;
                        this.events.startRotating.next()
                    }
                }
                if (this.activeScene.status >= Status.BLUR_LOADED) {
                    this.setAngle(startAngle + ((e.deltaX / this.glCanvas.clientWidth) * 360) / this.rotationSensitivity);
                }
            }
        })

        this.hammer.on('panend', async (e) => {
            panStarted = false;
            const scene = this.activeScene
            await this.setHdFrame()
            // this.glCanvas.style.transition = `0.1s filter linear`
            this.isTUrning = false;
            if (scene === this.activeScene) {
                this.loadImages(scene)
            }
        })

        if (!this.isTouch) {
            this.glCanvas.addEventListener("mousemove", (e) => {
                if (this.activeScene && this.activeScene.wire && !this.isTUrning) {
                    const poi = this.getPoiFromPos(e.offsetX / this.displayWidth, e.offsetY / this.displayHeight)
                    this.events.mouseMove.next({ poi: poi ? this.pois[poi] : undefined, clientX: e.clientX, clientY: e.clientY })
                }
            })
        }
    }


    public setContainer(container: HTMLElement) {
        this.container = container;
        this.mouseCanvas.style.visibility = "hidden"
        this.mouseCanvas.className = "mouseCanvas"

        this.divContainer.style.overflow = "hidden"
        this.divContainer.style.position = "relative"
        this.divContainer.style.margin = "auto"
        this.glCanvas.style.position = "absolute"

        this.container.appendChild(this.divContainer)
        this.divContainer.appendChild(this.glCanvas)
        this.divContainer.appendChild(this.mouseCanvas)
        this.handleTouch()
    }

    private async loadImages(targetScene: Scene) {
        this.loadImageIndex += 1;
        const lastLoadImageIndex = this.loadImageIndex
        // Active scene blur
        if (lastLoadImageIndex === this.loadImageIndex && this.activeScene.status < Status.BLUR_LOADED) {
            const res = await this.imageManager.loadImages(this.activeScene.frames.map(e => e.pic.sd.path).concat(this.activeScene.wire ? this.activeScene.frames.map(e =>
                e.wire.sd.path) : []))
            if (res) {
                targetScene.frames.forEach(frame => {
                    frame.pic.sd.image = this.imageManager.images[frame.pic.sd.path]
                    if (targetScene.wire) {
                        frame.wire.sd.image = this.imageManager.images[frame.wire.sd.path]
                    }
                })
                targetScene.status = Status.BLUR_LOADED
                this.events.loading.next(false);
            }
        }


        (async () => {
            while (lastLoadImageIndex === this.loadImageIndex) {
                for (let pic of this.activeScene.frames) {
                    if (this.imageManager.images[pic.pic.hd.path]) {
                        if (!this.drawer.gl.imageTexurePicCache.find(img => img === this.imageManager.images[pic.pic.hd.path])) {
                            this.drawer.preloadImage(this.imageManager.images[pic.pic.hd.path], false)
                            await sleep(0)
                        }
                    }
                    if (this.imageManager.images[pic.wire.hd.path]) {
                        if (!this.drawer.gl.imageTexureWireCache.find(img => img === this.imageManager.images[pic.wire.hd.path])) {
                            this.drawer.preloadImage(this.imageManager.images[pic.wire.hd.path], true)
                        }
                    }
                }
                await sleep(0)
            }
        })()

        // Other scene HD actual frame
        if (lastLoadImageIndex === this.loadImageIndex && this.activeScene.status >= Status.BLUR_LOADED) {
            const index = this.activeScene.frames.findIndex(f => f === this.getActualFrame())
            const queue = [];
            for (let scene of this.scenes.filter((scene, i) => (scene !== targetScene && scene.groupId === targetScene.groupId))) {
                queue.push(scene.frames[index].pic.hd.path)
                if (scene.wire) {
                    queue.push(scene.frames[index].wire.hd.path)
                }
            }
            await this.imageManager.loadImages(queue)
        }

        // Actual Scene HD frames
        if (lastLoadImageIndex === this.loadImageIndex && this.activeScene.status === Status.BLUR_LOADED) {
            const res = await this.imageManager.loadImages(this.activeScene.frames.map(e => e.pic.hd.path).concat(this.activeScene.wire ? this.activeScene.frames.map(e =>
                e.wire.hd.path) : []))
            if (res) {
                targetScene.frames.forEach(frame => {
                    frame.pic.hd.image = this.imageManager.images[frame.pic.hd.path]
                    if (targetScene.wire) {
                        frame.wire.hd.image = this.imageManager.images[frame.wire.hd.path]
                    }
                })

                targetScene.status = Status.FULL_LOADED
            }
        }

        // Other Scenes BLUR
        for (let scene of this.scenes.filter((scene, i) => scene !== targetScene && scene.status < Status.BLUR_LOADED)) {
            if (lastLoadImageIndex === this.loadImageIndex) {
                const res = await this.imageManager.loadImages(scene.frames.map(e => e.pic.sd.path).concat(scene.wire ? scene.frames.map(e =>
                    e.wire.sd.path) : []))
                if (res) {
                    scene.frames.forEach(frame => {
                        frame.pic.sd.image = this.imageManager.images[frame.pic.sd.path]
                        if (scene.wire) {
                            frame.wire.sd.image = this.imageManager.images[frame.wire.sd.path]
                        }
                    })
                    scene.status = Status.BLUR_LOADED
                }
            }
        }

        // Other Scenes HD
        for (let scene of this.scenes.filter((scene, i) => scene !== targetScene && scene.status < Status.FULL_LOADED)) {
            if (lastLoadImageIndex === this.loadImageIndex) {
                const res = await this.imageManager.loadImages(scene.frames.map(e => e.pic.hd.path).concat(scene.wire ? scene.frames.map(e =>
                    e.wire.hd.path) : []))
                if (res) {
                    scene.frames.forEach(frame => {
                        frame.pic.hd.image = this.imageManager.images[frame.pic.hd.path]
                        if (scene.wire) {
                            frame.wire.hd.image = this.imageManager.images[frame.wire.hd.path]
                        }
                    })
                    scene.status = Status.FULL_LOADED
                }
            }
        }
    }

    private async setHdFrame() {
        const frame = this.getActualFrame()
        const targetScene = this.activeScene
        await Promise.all([
            (async () => {
                if (!frame.pic.hd.image) {
                    frame.pic.hd.image = await this.imageManager.loadImage(frame.pic.hd.path)
                }
            })(),
            (async () => {
                if (this.activeScene.wire && !frame.wire.hd.image) {
                    frame.wire.hd.image = await this.imageManager.loadImage(frame.wire.hd.path)
                }
            })()
        ])
        if (frame === this.getActualFrame()) {
            // PAINT
            // this.drawer.paintPic(frame.pic.hd.image as HTMLImageElement, this.displayWidth * this.pxRatio, this.displayHeight * this.pxRatio)
            this.drawer.paint(frame.pic.hd.image as HTMLImageElement, frame.wire.hd.image)
            if (targetScene.wire) {
                // this.glCanvas.style.opacity = "1"
                this.mouseCanvas.width = (frame.wire.hd.image as HTMLImageElement).width
                this.mouseCanvas.height = (frame.wire.hd.image as HTMLImageElement).height
                this.mouseCtx.drawImage(frame.wire.hd.image as HTMLImageElement, 0, 0, this.mouseCanvas.width, this.mouseCanvas.height)
            } else {
                // this.glCanvas.style.opacity = "0"
            }
            this.glCanvas.style.filter = `blur(0px)`;
            this.lastActiveFrame = frame;
            this.activeFrame = frame;
        }
    }

    public async setScene(scene: number | string) {
        this.handleSize()
        const targetScene = typeof (scene) === "number" ? this.scenes[scene] : (this.scenes.find(e => e.name === scene) as Scene)
        this.events.loading.next(targetScene.status < Status.BLUR_LOADED)
        if (this.activeScene && this.activeScene.groupId !== targetScene.groupId) {
            this.setAngle(0)
        }
        if (this.activeScene !== targetScene) {
            this.activeScene = targetScene;
            this.drawer.resetImageCache()
            await this.setHdFrame()
            this.drawer.setColors(this.activeScene.wiresId.map(e => this.pois[e].color))
            this.loadImages(targetScene)
        }
    }
}