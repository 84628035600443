import React, { useEffect } from 'react';
import hive from "./index"
import { useState } from "@hookstate/core"

function Image() {
    const { isMobile, image, height, width } = useState(hive.state)

    return <div style={{
        background: "white",
        position: "absolute",
        height: height.get() + 'px',
        width: "100vw",
        zIndex: 2,
        display: "flex",
    }}>
        <div style={{
        }}>
            <div style={{
                width: "80vw",
                height: height.get() * 0.9 + "px",
                marginTop: height.get() * 0.1 + "px",
                // backgroundColor: "red",
                display: "flex",
                flexDirection: "column"
            }}>

                <div style={{
                    height: "1%"
                }}>
                </div>

                <div style={{
                    width: "100%",
                    height: "98%",
                    backgroundImage: `url(${image.get()})`,
                    backgroundPosition: "center center",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "contain",
                }}>
                </div>

                <div style={{
                    height: "1%"
                }}>
                </div>


            </div>
        </div>
        <div style={{
            width: "20vw",
            // backgroundColor: "blue",
        }}>

        </div>
    </div>
}

export default Image