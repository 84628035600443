import React, { useEffect } from 'react';
import { useState } from "@hookstate/core"
import hive from "./index"
import { Print, GetApp, FavoriteBorder } from '@material-ui/icons';
import { config } from 'rxjs';

function numberWithSpaces(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function ToolTip() {
  const { totalWidth, width, height, mainColor, typeColors, isTouch } = useState(hive.state)
  const { poi, lot } = useState(hive.overState)

  const a_width = height.get() * 0.45
  const a_height = height.get() * 0.19
  const color = lot.get() ? typeColors.get()[lot.get().type - 1] : [255, 255, 255]

  useEffect(() => {
    const el = document.querySelector("#tooltip") as HTMLElement
    hive.poiOverevent.subscribe(e => {
      const a_height = height.get() * 0.19
      const a_width = height.get() * 0.45
      const isOnBottom = e.clientY > height.get() * 0.3
      el.style.top = `${e.clientY - (isOnBottom ? a_height + a_height * 0.05 : a_height * -0.10)}px`
      el.style.left = `${e.clientX - a_width / 2}px`
      el.style.backgroundImage = `url(/gui/tooltip_${isOnBottom ? 'down' : "up"}.png)`
    })
  }, [])

  const iconSize = {
    width: a_height * 0.7 * 0.25 * 0.7,
    height: a_height * 0.7 * 0.25 * 0.7
  }

  return <div
    id="tooltip"
    style={{
      position: "absolute",
      width: a_width,
      height: a_height,
      backgroundSize: "100% 100%",
      pointerEvents: (isTouch.get() && poi.get()) ? 'initial' : 'none',
      opacity: poi.get() ? "1" : "0",
      transition: "opacity 0.1s"
    }}>
    {lot.get() && poi.value &&
      <div
        onClick={() => {
          hive.action(lot.nested('action').get(), lot.nested('target').get())
        }}
        style={{
          width: a_width * 0.90,
          height: a_height * 0.80,
          position: "absolute",
          top: a_height * 0.085,
          left: a_width * 0.045,
          display: "flex",
          justifyContent: "space-between",
        }}>
        <div style={{
          backgroundImage: `url(${lot.get().plan})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: a_width * 0.90 * 0.3,
          height: a_height * 0.80,
        }}>
        </div >

        <div style={{
          width: a_width * 0.90 * 0.57,
          height: a_height * 0.80,
          fontSize: a_height * 0.80 * 0.14,
          color: "#9D9D9D",
        }}>
          <div style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
            <div>
              Lot : {lot.nested("id").get()}
            </div>
            <div style={{
              background: "rgb(60, 211, 40)",
              borderRadius: a_height * 0.80 * 0.07 + "px",
              width: a_width * 0.90 * 0.57 * 0.5 + "px",
              color: "white",
              textAlign: "center"
            }}>
              Disponible
            </div>
          </div>

          <div style={{
            display: "flex",
            justifyContent: "space-between",
          }}>
            <div style={{
              display: "flex",
            }}>
              <div>
                Type
                </div>
              <div style={{
                backgroundColor: `rgb(${color[0]},${color[1]},${color[2]})`,
                width: a_width * 0.90 * 0.57 * 0.15 + "px",
                borderRadius: (a_width * 0.90 * 0.57 * 0.15) * 0.5 + "px",
                textAlign: "center",
                color: "white",
                marginLeft: a_width * 0.90 * 0.57 * 0.02 + "px"
              }}>
                T{lot.get().type}
              </div>
            </div>
            <div>
              Surf : {lot.nested("surface").get()}m2
            </div>
          </div>
          <div style={{
            color: `#${mainColor.get()}`,
            fontSize: a_height * 0.80 * 0.16,
            fontFamily: "MuktaBold",
          }}>
            Prix : {numberWithSpaces(lot.nested("price").get())}€
            {/* {lot.nested("price").get() &&
              <span>Prix : {lot.nested("price").get()}</span>
            } */}
          </div>
          <div style={{
            display: "flex",
            // background: "red",
            height: a_height * 0.25,
            lineHeight: a_height * 0.25 + "px",
            fontSize: a_height * 0.80 * 0.12,
          }}>

            <div style={{
              display: "flex",
              marginRight: a_width * 0.007 + "px",
            }}>
              <div style={{
                width: a_width * 0.1,
                height: "100%",
                backgroundImage: "url(/gui/2d.svg)",
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                marginRight: a_width * 0.007 + "px"
              }}>
              </div>
              <div>
                Plan 2D
              </div>
            </div>

            {lot.get().axo &&
              <div style={{
                display: "flex",
              }}>
                <div style={{
                  width: a_width * 0.1,
                  height: "100%",
                  backgroundImage: "url(/gui/3d.svg)",
                  backgroundSize: "contain",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  marginRight: a_width * 0.007 + "px"
                }}>
                </div>
                <div>
                  Plan 3D
              </div>
              </div>
            }

          </div>
        </div>

        <div style={{
          width: a_width * 0.90 * 0.1,
          height: a_height * 0.80,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
          <div style={{
            backgroundColor: `#${mainColor.get()}`,
            width: '100%',
            height: a_height * 0.70,
            borderRadius: a_width * 0.90 * 0.1 * 0.5,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            flexDirection: "column"
          }}>
            {['', '', ''].map((icon, i) => <div key={i} style={{
              background: 'white',
              height: a_height * 0.7 * 0.25,
              lineHeight: a_height * 0.7 * 0.25 * 1.4 + "px",
              width: a_height * 0.7 * 0.25,
              borderRadius: a_height * 0.7 * 0.25 * 0.5,
              fontSize: a_height * 0.7 * 0.25 * 0.7,
              textAlign: "center",
              color: `#${mainColor.get()}`,
            }}>
              {i === 0 && <GetApp style={iconSize}></GetApp>}
              {i === 1 && <Print style={iconSize}></Print>}
              {i === 2 && <FavoriteBorder style={iconSize}></FavoriteBorder>}
            </div>
            )}
          </div>
        </div>
      </div>
    }

  </div>
}

export default ToolTip