import React, { useEffect } from 'react';
import { useState } from "@hookstate/core"
import hive from "./index"
import { Favorite, FavoriteBorder } from '@material-ui/icons';

function numberWithSpaces(x: number) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function LotCard(props: any) {
  const { height, mainColor, searchResult, totalWidth, typeColors, favoriteList } = useState(hive.state)
  const lot = useState(props.lot).get()

  const pri = (p: any) => numberWithSpaces(p)

  const cardWidth = totalWidth.get() * props.width
  const cardHeight = height.get() * props.height

  return <div style={{
    width: cardWidth + "px",
    height: cardHeight + "px",
    background: "white",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    color: `rgb(157, 157, 157)`,
  }}>
    <div style={{
      height: cardHeight * 0.96,
      display: "flex",
      justifyContent: "center"
    }}>
      <div style={{
        width: cardWidth * 0.97,
        height: cardHeight * 0.96,
      }}>

        <div style={{
          height: cardHeight * 0.96 * 0.25,
          lineHeight: cardHeight * 0.96 * 0.25 + "px",
          fontSize: cardHeight * 0.1 + "px",
          marginLeft: cardHeight * 0.1,
          display: "flex",
          justifyContent: "space-between"
        }}>
          <div>Lot : {lot.id}</div>


          <div style={{
            display: "flex"
          }}>
            <div

              onClick={() => hive.action(lot.action, lot.target)}
              style={{
                textAlign: "center",
                height: cardHeight * 0.96 * 0.23,
                lineHeight: cardHeight * 0.96 * 0.23 + "px",
                width: cardWidth * 0.97 * 0.30 + "px",
                backgroundColor: `#${mainColor.get()}`,
                marginRight: cardWidth * 0.01 + "px",
                borderRadius: cardHeight * 0.03 + "px",
                color: "white",
                cursor: "pointer",
              }}>
              VISITER
            </div>
            <div
              onClick={() => { hive.toggleFavorite(lot.id) }}
              style={{
                background: "white",
                border: `0.5px solid #${mainColor.get()}`,
                height: cardHeight * 5 * 0.04 + "px",
                lineHeight: cardHeight * 5 * 0.04 + "px",
                width: cardHeight * 5 * 0.04 + "px",
                borderRadius: cardHeight * 5 * 0.04 * 0.5 + "px",
                display: "flex",
                justifyContent: "center",
                cursor: "pointer",
              }}>
              <div style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                color: "#ff0057",
              }}>
                <div style={{
                  height: cardHeight * 5 * 0.03 + "px"
                }}>
                  {!favoriteList.find((e) => e.get() === lot.id) &&
                    <FavoriteBorder style={{
                      width: cardHeight * 5 * 0.030 + "px",
                      height: cardHeight * 5 * 0.030 + "px",
                    }}></FavoriteBorder>
                  }
                  {favoriteList.find((e) => e.get() === lot.id) &&
                    <Favorite style={{
                      width: cardHeight * 5 * 0.030 + "px",
                      height: cardHeight * 5 * 0.030 + "px",
                    }}></Favorite>
                  }

                </div>
              </div>
            </div>
          </div>

        </div>

        <div style={{
          display: "flex"
        }}>
          <div style={{
            width: cardWidth * 0.45 * 0.97 + 'px',
            height: cardHeight * 0.96 * 0.75 + "px",
            backgroundImage: `url(${lot.plan})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat"
          }}>
          </div>
          <div style={{
            width: cardWidth * 0.55 * 0.97 + 'px',
            height: cardHeight * 0.96 * 0.75 + "px",
            fontSize: cardHeight * 0.1 + "px",
            marginLeft: cardWidth * 0.04,
            // background: "red",
          }}>
            <div style={{
              display: "flex",
              marginBottom: cardHeight * 0.05 + "px",
              marginTop: cardHeight * 0.05 + "px",
            }}>
              <div>
                Type :
              </div>
              <div style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
              }}>
                <div style={{
                  height: cardHeight * 0.17 + "px",
                  width: cardHeight * 0.17 + "px",
                  lineHeight: cardHeight * 0.17 + "px",
                  borderRadius: cardHeight * 0.17 * 0.5 + "px",
                  textAlign: "center",
                  background: `rgb(${typeColors.get()[lot.type - 1][0]},${typeColors.get()[lot.type - 1][1]},${typeColors.get()[lot.type - 1][2]})`,
                  marginLeft: cardHeight * 0.09 * 0.5,
                  color: "white",
                }}>
                  T{lot.type}
                </div>
              </div>

            </div>
            <div style={{
              marginBottom: cardHeight * 0.02 + "px",
            }}>
              Surface : {lot.surface}m2
              </div>
            <div style={{
              fontSize: cardHeight * 0.13 + "px",
              fontFamily: "MuktaBold",
              color: `#${mainColor.get()}`,
            }}>
              {pri(lot.price)}€

              </div>
          </div>
        </div>

      </div>
    </div>
  </div>
}


export default LotCard