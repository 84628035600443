import React, { useEffect } from 'react';
import './App.css';
import hive from "./hive"
import LeftMenu from "./hive/leftMenu"
import Header from "./hive/header"
import ToolTip from "./hive/tooltip"
import MobileFLoorMenu from "./hive/mobileFloorMenu"
import { useState } from "@hookstate/core"
import { R_HEADER_TOP_HEIGHT, R_HEADER_HEIGHT, R_MOBILE_HEADER_HEIGHT } from './hive/constant';
import GoBack from './hive/GoBack';
import Image from './hive/Image';
import Button from './hive/Button';
import BotMenu from './hive/BotMenu';
import MobileLogo from './hive/MobileLogo';
import Tour from './hive/Tour';
import InfoCard from './hive/InfoCard';
import Compas from './hive/Compas';
import SearchButton from './hive/SearchButton';
import Loading from './hive/Loading';
import { FavoriteBorder } from '@material-ui/icons';
import Search from './hive/Search';
import LotCard from './hive/LotCard';

function FavoriteTarget({ lotState }: any) {
  // width: totalWidth.get() * 0.49,
  // height: height.get() * 0.65,
  const { height, totalWidth, mainColor } = useState(hive.state)

  const lot = useState(lotState)
  const targetLot = Object.values(hive.lots).find((l: any) => l.id === lot.get()) as any

  return <div>
    {!targetLot && <>
      <div style={{
        width: "100%",
        height: height.get() * 0.65,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}>
        <div style={{
          display: "flex",
          justifyContent: "center",
          width: totalWidth.get() * 0.49,

        }}>
          <div style={{
            width: height.get() * 0.45,
            height: height.get() * 0.22,
            textAlign: "center",
            color: `#${mainColor.get()}`
          }}>
            <div style={{
              fontSize: height.get() * 0.03,
              fontFamily: "MuktaBold",
            }}

            >COMPARER</div>
            <div style={{
              fontSize: height.get() * 0.2 + "px",
              margin: -height.get() * 0.1 + "px",
            }}>+</div>
            <div
              style={{
                fontSize: height.get() * 0.02 + "px",
              }}
            >Glissez déposer vos favoris pour les comparer</div>
          </div>
        </div>

      </div>
    </>}

    {targetLot &&
      <div style={{
        width: "100%",
        height: height.get() * 0.65,
        backgroundImage: `url(${targetLot.plan})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center",
      }}>
      </div>
    }
  </div>
}

function Favorites() {
  const { height, totalWidth, mainColor, favoriteList, favoriteLeft, favoriteRight } = useState(hive.state)

  const overDrop = useState("")
  const lotDrop = useState("")

  const getLotfromId = (id: string) => {
    const res = Object.values(hive.lots).find((lot: any) => lot.id === id)
    return res
  }

  const touchStart = (fav: string) => {
    (document.querySelector("#grabTooltip") as HTMLElement).style.visibility = `initial`;
    overDrop.set("")
    lotDrop.set(fav)
  }

  const touchMove = (x: number, y: number) => {
    if (x !== 0 && y !== 0) {
      (document.querySelector("#grabTooltip") as HTMLElement).style.top = `${y}px`;
      (document.querySelector("#grabTooltip") as HTMLElement).style.left = `${x}px`;
      const el = (document.elementFromPoint(x, y) as HTMLElement).getAttribute("id");
      (document.querySelector("#grabTooltip") as HTMLElement).style.visibility = (el === "drop_0" || el === "drop_1") ? "initial" : "hidden";

      if (el !== null) {
        if (overDrop.get() !== el) {
          overDrop.set(el)
          console.log("DROPAGE", el);
        }
      }
    }
  }

  const touchEnd = () => {
    if (overDrop.get()) {
      if (overDrop.get() === "drop_0") {
        favoriteLeft.set(lotDrop.get())
      }
      if (overDrop.get() === "drop_1") {
        favoriteRight.set(lotDrop.get())
      }
    }
    lotDrop.set("")
    overDrop.set("");
    (document.querySelector("#grabTooltip") as HTMLElement).style.visibility = `hidden`;
  }

  useEffect(() => {
    console.log("EFFECT");
  })

  return <div style={{
    position: "absolute",
    height: height.get(),
    background: "#dadada",
    width: totalWidth.get(),
    zIndex: 1,
  }}>
    <div
      id="grabTooltip"
      style={{
        position: "fixed",
        width: height.get() * 0.1,
        height: height.get() * 0.1,
        backgroundImage: "url(/gui/drag.png)",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        zIndex: 5,
        visibility: "hidden",
        pointerEvents: "none",
      }}>

    </div>

    {/* return space */}
    <div style={{
      height: height.get() * 0.07,
    }}>

    </div>

    {/* comparator */}

    <div style={{
      height: height.get() * 0.70,
      display: "flex",
      justifyContent: "space-evenly",
    }}>
      {[0, 1].map(e =>
        <div key={e}
          style={{
            width: totalWidth.get() * 0.49,
            height: height.get() * 0.65,
            border: overDrop.get() === `drop_${e}` ? `2px #${mainColor.get()} solid` : `2px #${mainColor.get()} dashed`,
            backgroundColor: overDrop.get() === `drop_${e}` ? `#${mainColor.get()}66` : "inherit"
          }}
        >
          <div
            id={`drop_${e}`}
            style={{
              position: "absolute",
              width: totalWidth.get() * 0.49,
              height: height.get() * 0.65,
            }}>
          </div>
          <FavoriteTarget lotState={e == 0 ? favoriteLeft : favoriteRight}></FavoriteTarget>
        </div>
      )}

    </div>

    {/* list */}
    <div style={{
      height: height.get() * 0.225,
      width: "100vw",
      display: "flex",
      // border : "1px solid blue"
      overflowX: "scroll",
    }}>
      {favoriteList.map((fav, i) =>
        <div
          draggable="true"
          onDragStart={(e) => {
            console.log("START DRAG");
            e.dataTransfer.setData('Text', "");
            touchStart(fav.get())
          }}

          onDrag={(e) => {
            console.log("DRAG");
            console.log(e.pageX);
            touchMove(e.pageX, e.pageY)
          }}

          onDragEnd={(e) => {
            console.log("DRAG END");
            touchEnd()
          }}

          onTouchStart={() => {
            touchStart(fav.get())
          }}

          onTouchMove={(e) => {
            const x = e.targetTouches[0].clientX
            const y = e.targetTouches[0].clientY
            touchMove(x, y)
          }}

          onTouchEnd={(e) => {
            touchEnd()
          }}
          key={fav.get()}
          style={{
            cursor: "grab",
            width: totalWidth.get() * 0.25,
            minWidth: totalWidth.get() * 0.25,
            height: height.get() * 0.20,
            // background: "red",
            marginLeft: height.get() * 0.01,
          }}
        >
          <LotCard width={0.25} height={0.22} lot={getLotfromId(fav.get())}></LotCard>
        </div>
      )}
    </div>
  </div>
}

function App() {
  const { isMobile, image, botMenu, back, tour, infoCard, compas, leftMenu, searchButton, search, favorites } = useState(hive.state)

  useEffect(() => {
    hive.vision.setContainer(document.querySelector("#visionContainer") as HTMLElement)
    hive.init()
    return () => { }
  }, [])

  return (
    <div className="App">
      {!isMobile.get() && <Header></Header>}
      {(isMobile.get() && !back.get()) && <MobileLogo></MobileLogo>}
      {back.get() && <GoBack></GoBack>}
      {image.get() && <Image></Image>}
      {botMenu.get() && <BotMenu></BotMenu>}
      {infoCard.get() && <InfoCard></InfoCard>}
      {tour.get() && <Tour></Tour>}
      {isMobile.get() && leftMenu.get().length > 0 && <Button onClick={() => { hive.toggleFloorMenu() }}></Button>}
      {compas.get() && <Compas></Compas>}
      {(searchButton.get() && !isMobile.get()) && <SearchButton></SearchButton>}
      {search.get() && <Search></Search>}
      {favorites.get() && <Favorites></Favorites>}

      <Loading></Loading>
      <div id="visionContainer" style={{
        width: "100vw",
        height: isMobile.get() ? "100vh" : `calc(100vh - (100vh * ${R_HEADER_HEIGHT}))`
      }}>
      </div>
      {isMobile.get() && leftMenu.get().length > 0 && <MobileFLoorMenu></MobileFLoorMenu>}
      {!isMobile.get() && leftMenu.get().length > 0 && <LeftMenu></LeftMenu>}
      <ToolTip></ToolTip>
    </div>
  );
}

export default App;
