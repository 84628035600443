export const fragmentShaderSource = `

precision mediump float;

// our texture
// uniform sampler2D u_image;
uniform sampler2D u_image;
uniform sampler2D u_mask;
uniform vec4 u_wires[62];
uniform int u_wires_size;
uniform int u_width;
uniform int u_height;

// uniform float poisBright[20];

// the texCoords passed in from the vertex shader.
varying vec2 v_texCoord;

#define COTE 0.002
void main() {

//    vec4 image = texture2D(u_image, v_texCoord);
  vec4 mask = texture2D(u_mask, v_texCoord);
  vec4 image = texture2D(u_image, v_texCoord);
  if (mask != vec4(1.,1.,1.,1)) {
    for (int i=0; i <= 61; i++) {
      if (i >= u_wires_size) {
        break;
      }
      if (int( floor(mask[0] * 256.00000) ) == i) {
          float alpha = u_wires[i][3];

          int antialiasing = 1;
          
          gl_FragColor = mix(image, u_wires[i], alpha);
          return;
      }
    }
  } 
  gl_FragColor = image;
}

`

