import React, { useEffect } from 'react';
import hive from "./index"
import { useState } from "@hookstate/core"
import { Layers } from '@material-ui/icons';

function Button(props: any) {
    const { totalWidth, width, height, mainColor } = useState(hive.state)

    return (
        <div
            onClick={props.onClick}
            style={{
                border: '1px solid grey',
                position: "absolute",
                width: height.get() * 0.1,
                height: height.get() * 0.1,
                marginTop: height.get() * 0.15 + "px",
                left: totalWidth.get() - (totalWidth.get() * 0.07),
                borderRadius: height.get() * 0.015 + "px",
                background: "#FFFFFF",
                opacity: "0.7",
                color: `#${mainColor.get()}`,
                textAlign: "center",
                fontSize: height.get() * 0.03,
                zIndex : 3,
            }}>
            <div style={{
                width: "100%",
                height: height.get() * 0.05
            }}>
                <Layers style={{
                    width: height.get() * 0.05,
                    height: height.get() * 0.05,
                    marginTop: height.get() * 0.01
                }}></Layers>
            </div>
            <div style={{
            }}>
                Etages
      </div>
        </div>
    )
}

export default Button